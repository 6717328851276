<template>
<Prime-Page>
  <ion-content>
    <ion-grid class="margin-bottom-twenty full-width full-height">
      <ion-row class="ion-justify-content-center full-height">
        <ion-col size-sm="10" size-md="8" size-lg="6" class="full-height">
          <h3>User Profile</h3>
          <form @submit.prevent="sendPayload" novalidate="true">
            <ion-row>
              <ion-col class="ion-margin-end" size="3">
                <h6>Prefix</h6>
                <select class="form-control" v-model="user.account.title">
                  <option v-for="option in copy.titleOptions" :key="option" :value="option">{{ option }}</option>
                </select>
              </ion-col>
              <ion-col>
                <h6>First Name</h6>
                <input v-model.trim="user.account.firstName" class="form-control" type="text" placeholder="Jason" />
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="ion-margin-end">
                <h6>Last Name</h6>
                <input v-model.trim="user.account.lastName" class="form-control" type="text" placeholder="Gonalez" />
              </ion-col>
              <ion-col size="3">
                <h6>Suffix</h6>
                <select class="form-control" v-model="user.account.suffix">
                  <option v-for="option in copy.suffixOptions" :key="option" :value="option">{{ option }}</option>
                </select>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <h6>Role</h6>
                <select v-if="isClientPartner || isLumerisAdmin" class="form-control" v-model="systemRole" disabled>
                  <option v-for="(name, id) in $languagePack.systemRoles" :key="id" :value="id">{{ name }}</option>
                </select>
                <select v-else class="form-control" v-model="user.professional.professionalRoleId">
                  <option v-for="(name, id) in $languagePack.professionalRoles" :key="id" :value="id">{{ name }}</option>
                </select>
              </ion-col>
            </ion-row>
            <button type="submit" class="prime-button button-block fade-in margin-top-fourty margin-bottom-twenty" :class="{ 'button-pending': loading }">
              <span>Save Updates</span>
              <ion-spinner name="dots" duration="1200" />
            </button>
          </form>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</Prime-Page>
</template>
<script>
import { send as httpSend } from "@/services/Api";
import PrimePage from "@/components/Global/PrimePage";

export default {
  name: "UserProfile",
  components: {
    PrimePage
  },
  props: {
    user: Object
  },
  data() {
    return {
      copy: this.$languagePack,
      formIsChanged: false,
      loading: false
    };
  },
  computed: {
    isLumerisAdmin() {
      return this.user.professional.role.includes("LUMERIS_ADMIN");
    },
    isClientPartner() {
      return this.user.professional.role.includes("CLIENT_PARTNER");
    },
    systemRole() {
      return this.isLumerisAdmin ? "LUMERIS_ADMIN" : "CLIENT_PARTNER";
    }
  },
  methods: {
    handleTimeZoneSelectChange(event) {
      this.formIsChanged = true;
      this.currentPatient.account.timeZone = event.target.value;
    },
    sendPayload: async function() {
      this.loading = true;
      const method = "put";
      const path = document.config.professionalSettings;
      const payload = this.user;

      httpSend({ path, method, authToken: true, payload })
        .then(() => {
          this.$ionic.toastController
            .create({
              header: "Success!",
              message: "User Settings have been updated",
              duration: 1000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());

          this.loading = false;
          this.formIsChanged = false;
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Error",
              message: "Could not save User Settings. Please try again later.",
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());

          khanSolo.log(error);
          this.loading = false;
        });
    }
  }
};
</script>
<style scoped>
.size-1 {
  display: inline-block;
  width: 25%;
}
.size-3 {
  display: inline-block;
  width: 73%;
}

.prime-form-group + .prime-form-group {
  margin-left: 2%;
}
</style>
